import React from "react";
import Header from "./Header";
import Footer from "../Components/Footer";

function Refund() {
  return (
    <div>
      <Header />
      <div className="footer-pages-privacy-cont">
        <h1>REFUND AND CANCELLATION POLICY</h1>
        <p>
          • RETURN The Company will issue refunds to students who request for a
          refund within 3 days of their first subscription only in genuine
          cases.
          <br></br>
          <br></br>
          Note: In case the admins feel the reason for refund is genuine and
          student can't proceed ahead with the course, we have an option to
          refund 60% of enrollment fee to the student(The 40% deduction is for
          the taxes, operational and payment gateway fees.)
          <br></br><br></br>• EXCHANGE 'Exchange' is the action or process of Exchanging
          of the services provided by the Companyfor any such other services
          provided by the Company. User can request for an Exchange of Course
          availed by the User on the platform with another course available on
          the platform after placing a request for the Course but before
          accessing and availing the course. An intimation shall be provided by
          the Company to the User seeking either "Exchange” and the User shall
          receive a confirmation regarding the replacement. POINTS TO BE NOTED:
          • The user can request for an exchange before accessing the course
          online on the platform of the User. • If the Company disagrees an
          exchange request due to non-technical issue, User can file a dispute.
          • We encourage the User to review the course and its subjective nature
          before making the request to avail such services. • User needs to
          raise the exchange request within 7 days from availing the course
          online. REFUND PROCESS(Only enrolled under limited time Feesback
          Offer)<br></br>
          1. For Refund Request the user may place a request for the same on the
          chat box of the website, the Representative of the Company shall reach
          out to the User on the Registered number provided by the User on the
          Platform.<br></br>
          2. The Refund will be processed if the user is facing some genuine
          problem and can not proceed ahead with the subscription.<br></br>
          3. The Company be at the sole discretion to refund to the User and all
          refunds shall be processed after such statutory deductions.<br></br>
          4. All refunds shall be credited within 5-7 business days to the
          source of the payment received by the Company from the User.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
