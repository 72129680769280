import React from "react";

import "./About.css";
import Header from "./Header";
import Footer from "../Components/Footer";
function About() {
  return (
    <>
      <Header />

      <div className="footer-pages-who">
        <h1>Who Are We ?</h1>
        <p>
          Upskillmafia, a product by Tutedude, is a virtual college where
          students can come and learn along with their peers. Our aim is to
          provide an offline classroom experience in an online world. To turn
          this into reality, we have taken up this initiative. The perks are
          that they would get mentorship by the successful people. This would
          also help them shorten their failure or struggle curves and reach
          their goal faster than ever before. We have listed some of the skills
          we are mentoring on at present.Those who want to learn any skill by
          experts in their respective field can learn by registering through us.
        </p>
        {/* <button
          className="footer-pages-find-course-btn"
          onClick={() => {
            window.location.href = "https://tutedude.com/#skill-div";
          }}
        >
          Find a Course
        </button> */}
      </div>
      <div className="footer-pages-our">
        <h1>Our Vision</h1>
        <p>
          To make education easily accessible to everyone.<br></br>
          I, Shivam Goyal(undergrad at IIT Delhi), always wanted to do a startup
          but was always engaged in college activities and was directionless on
          how to move forward then an idea struck that everyone needs a mentor
          in his/her life. And then the tutedude started. Even while making the
          website and app I wished I already had the platform because there was
          a dire need of mentoring in web development and app development.
        </p>
      </div>
      {/* <Footer /> */}
      <Footer />
    </>
  );
}

export default About;
