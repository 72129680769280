import React from "react";
import "./PrivacyPolicy.css";
import Header from "./Header";
import Footer from "../Components/Footer";

function Shipping() {
  return (
    <div>
      <Header />
      <div className="footer-pages-privacy-cont">
        <h1>Shipping and delivery policy</h1>
        <p>
          All of our products are digital products and are therefore delivered
          immediately after purchase. You get immediate access to the product
          after payment. There is no physical mailing or specific delivery time
          unless otherwise stated. In the unlikely event that we are unable to
          deliver a product immediately after purchase, we will notify you as
          soon as possible. All our products are delivered digitally and there
          is no postal delivery method. Once your payment is complete, you will
          have immediate access to the product. We encourage you to keep your
          email address and other contact information up to date to ensure you
          get the access you need. If you have any questions or problems with
          the delivery of the product, please feel free to contact us. We will
          do our best to resolve any problems and
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Shipping;
