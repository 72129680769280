import React from 'react'
import './Contact.css'
import { useState } from 'react'
function Contact() {
  const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function handleInputChange(e) {
        if(e.target.type === 'text') {
            setName(e.target.value)
        } else if(e.target.type === 'email') {
            setEmail(e.target.value)
        } else {
            setMessage(e.target.value)
        }
    }
  return (
    <div>
    <div className='footer-pages-contact'>
        <div className='footer-pages-left'>
            <h1>Contact Us</h1>
            <hr className='footer-pages-line'></hr>
            <p>Want to know more about us? Feel free to contact us.</p>
            <p>Suncity Success tower, sector 65, Gurugram, Haryana</p>
        </div>
        <div className='footer-pages-right'>
            <input type='text' placeholder='Name' onChange={handleInputChange}></input>
            <input type='email' placeholder='Email' onChange={handleInputChange}></input>
            <textarea placeholder='Message' onChange={handleInputChange}></textarea>
            <button className='footer-pages-email-btn'>SEND EMAIL</button>
        </div>
    </div>
    <div className='footer-pages-email-support'>Please mail us at support@tutedude.com for any queries</div>
    </div>
  )
}

export default Contact