import React from "react";
import "./styles.css";
function Header() {
  return (
    <a className="footer-pages-header" href="https://upskillmafia.com">
      Upskillmafia
    </a>
  );
}

export default Header;
